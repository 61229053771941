let observer = null

if (typeof window !== 'undefined' && 'IntersectionObserver' in window) {
    observer = new IntersectionObserver(processEntries, {
        root: null,
        rootMargin: '0px',
        threshold: 0.2,
    })
}

function processEntries(entries) {
    entries.forEach(processEntry)
}

function processEntry(entry) {
    if (!entry.isIntersecting) return

    animateElement(entry.target)
    animateChildren(entry.target)
    observer.unobserve(entry.target)
}

function animateChildren(element) {
    Array.from(element.children).forEach((child, index) => {
        setTimeout(() => animateElement(child), index * 150)
    })
}

function animateElement(element) {
    element.style.transitionProperty = 'opacity, transform'
    element.style.transitionDuration = '0.5s'
    element.style.opacity = 1
    element.style.transform = null
    element.style.overflow = null
}

export default {
    mounted(el) {
        prepareElement(el)
        Array.from(el.children).forEach(prepareElement)
        observer.observe(el)
    },
    unmounted(el) {
        observer.unobserve(el)
    },
}

function prepareElement(element) {
    element.style.transitionProperty = null
    element.style.opacity = 0
    element.style.transform = 'translateY(5rem)'
    element.style.overflow = 'hidden'
}
